export const allowed = {
    "0xb8bcbb39a04a3e1eb419904ac6209a6c549e7ad7":"0x5b0f4d3ec31813b3b8a264f500c0c98c31123da3c399867719e17dcce91b018239d40e8a21acae2cf3a40024101f606ad7b27b18079cec09485a3245de398f971c",
    "0xb305a5433e3eb039f138d7c577809bd51cb9448d":"0x8b001cbe370af4bd662a5e27924791cfae101ccc3f751e690092a21241caf2dd3742d32c0cceff46a57b6ecaa97a52e112db71461df8ba5bcda2ee6f31286c571b",
    "0xf3745f8297f75f4519df93775ad50efb33116803":"0xb236b9045c19a246199f2fe958955e50074a82702a412af5f6eca0c3be4dcca46a14eb21c8dab6f8b25756a07d3b1c4383e15c9c35196ac571cd768d1071c8271c",
    "0xfd84c2475446ef4ec1fbea9b7894458482473ffa":"0x77cf17f77abe476285dd4792d18df29db9626cd131ac57ed7ca91c6aebd82f420be480367cbd17495de579326ec633c507d45215435b7dfe6cd167e4c874698e1b",
    "0x425d397aba7e4076d1b0fc3e49259f567f8b6c72":"0x5fc158fbb3e0ac4522c55fe24c34c163afb200d23d122edb35eca25386a01c73458bd95bd43b6599821e3f1c1dd72bf61000f1c00af5944cf429370e9beae0f41b",
    "0x82b0313678d920db34151c82212f83aa80f33133":"0xac7f8d870b603179cb8c156f183cae8cab526bf0fecc3153bbb6b61f94dda9a9419a6a9aefd4e38b782a6d75e76dffadb15d3486d9c53d57ce8d39145849f7f61c",
    "0xffda0ee71544467687722f5072b71d692b2668ac":"0x921d5423ee933d5e7f9f70da3ed6adbb1202a3fc1029082a59f1b917e2768e063db63f663a3606a8c85f5d76061dee563c5ab5adc585df65edae85f2075c18931c",
    "0x6daacf9741223a4ddf7dfce4094b41e1dd348c90":"0x776f6e7056a7cac512aec6f0a2decc3c17c8bdf6f400f43f21f3bc4b3fc8b21618c2bdaa0cea7c28647888f4cb1b6913a8d0a9e6fb1d846867616a092cd6d6f01c",
    "0x9a7d46e369dca4d7d4c2ddc990bfc52f969df5b3":"0x9c46581a2e5fb83e8630d83a1dc6fcaf417c82cfe9489d9bfb52c6021e9f23a03c7ce0e701cd86fe075aab034d7ac885f047b14df20c510a6986b8e49e95a9321b",
    "0xbcdd8bb5b46db74eee36092dbbd7a417025febab":"0x584211fe9a97ce1ca9f76886ed7e5fad9e9330cb7c4955d8508fa90cf3bc007f4a709c02c9507bcdded9cebffd0c82abd02b7b31dbc0bca51e792ebb0dee889c1c",
    "0x503194afaae5e9c680c3119d25f3b25ee34bfe1b":"0x7e1dacaf9feb1c4d30fb2e002d1338dfc7c96e4f3df92524775a47271cebb321613acd141502fc745bd334e2d30f2240c23731cb7ef7f4a90d9e9dccb6f255421c",
    "0xc7f7238f5ea4bbb32f27eee874fda78d47a32795":"0xbe3bbdde345785c05902c69c03c707529fe22b01894a97bcbf191689d1a975435cf080d906bc7a01084aaecb9ffdf7f5328760fa8a871ad3ff05c9473441210f1c",
    "0xb8a2f9ba20288bad6219bb20ca9e9de6b50aa016":"0x09a6bcd65cf147e0b2210a6428447f415ebd013b5b38e971ac8e382473c5d0ea4b6d83a0a87cb03d43b8b2cdf10f2b8701f46bca16e8fbb5d706a5d3963125791b",
    "0x501b84e9d7b55667cbc1897d5d6c2ee93e51416f":"0x539243e6f80167d8ae27104b75991bf05a3ca610f3ce3002dbf261ea1edc33e72f7bec02d8f0ff4ed9a04c4134bb7b97b2c2a51987386edb13c05bf450af88f01c",
    "0x2d6d17c6a85f611c4d362f6894295e37be9b855d":"0x29142e90be12b639857fe97fc39ee994fa7ccbc5ae2b0c82cf6673a8cbe3208721eff93d21f6babc1fc8a9b58a514f7a265a1ce405c726559bbfb2b9889b48fa1b",
    "0x29325df4556dec8cb8a1985a0c63d125671e7b37":"0x28ee680802bf339f9f8e7b9d4a449d0b3b366ca5eb24d31047d702d0b09ce274135a71805662f6ff9b2981bc5b780235b25021d128734e8dc36cbc37de1b405a1b",
    "0x2519c3a03ae90e9d3dbdc76adca56dd3125fc8d7":"0x0cc68252722a94ea6f28b1c05fc45e0fdde17946c35f1f47d8fea9c3543253712008e457387e1516be52bf520a72590e9211c7de02da472bdbf4013101641f3c1b",
    "0xef223121a3b7e62f8a560361417e85313e6bfa62":"0x6ec8af10495c7138c64041b0936577a1b749c8fa8263fd85bf2dd9c3a2ea2acb25051d8cc3b70e35abf0d1860c099ce677272329f8d2fa8dea2a237733814b0c1b",
    "0x0d0716935b41511cf3dd57e4409f673e958c5e2b":"0x2a08511cfcf5075cf2673c773adc2604151a6d23551397d69fb96685fa4a449644e899a626fd4bb7837d043223da6811609fe4c355597b785e7a8129116209261b",
    "0xde9b0c3f28c517584e0d680e3df2608fac887c39":"0xd8b4c8ab68eb257426976fef10051545ed9013e43eafedcdca40e8f3f3f70afb7f685a36e989e929ae3afa396002825613e8461ee3724153f96908f61b99cb211c",
    "0x86e5cd46359758c50543c903fed3724e8afde5ac":"0xb66e8a7b3529b6560df416668366040105877e98cd8f5265bbf147b347b6a6c23b06efb5db98816548dd1adb872f1661189afce06c608ac7ade53f40e6b8deb11b",
    "0x37d5079a312c77e333a8ff7f2490769a67eac65e":"0xca706f07435301df141fd7a43705ab472cf6fe29d3aaf0e381bef50f538fced567e38318210e580946d9f36c4739e04406fc00e7d12bf5ef6a39c9f83fb29d2f1c",
    "0x0f9cab240ccc1bc9abfd7e6d57dcf57680034489":"0xfa8d092970fd71472356d76b6639dd6f6ed84e8b0fd4f9422cfe0c4b834832653d6ff63ad4600531f4e37e438c3defc0e37d3fa0724657ca476c647ebc821c081b",
    "0xb6eba36c13aaf118b3ed6d1b56c11e48c064f334":"0xaa1cee97041dc04a6278cc59af381a343c0aa6e584f11781adb0b3a62e28541e2b88387e8165918e280afa02df972d2c5740b5769b016aadcbc944a5886268e51c",
    "0x4be68b11bdc0380346e881fbd93c9c525e15948f":"0x35a86d06a35a5353031e4a4ff395b3278639d059da4933ad47d00da5cd4bd8d14743228813e33a4b4bbbd2b128f375f11ef1771973ae67beb54fc3a23dcb72e61c",
    "0x044d13533ad875088b88fe818c447c15ba66e5ba":"0xa4781675cab81cac4a4f7f2afc23972002b5bea57b3684e8bf8c6329a4ab2f732d08ed73f062bf0d14017868a575e4f338bc36929bc8b24bf304b20939245f6c1c",
    "0xee9c61d3ba13773d34a52517b6cb28dcd38b087a":"0xc29435e0e65954a66ed036db2523db4707e9b795451a9b5a98abf74889dad9d04f9b238b9bff1f935ec0113d96f191b4f6b94ea7ab4e63d2c9d1c0daa6402f2f1b",
    "0x51e8b0f8e608980dccdd3c65d66639b54327b4b3":"0xfd76b4fc5fe95ff58b9aa972a363ddf948b3303057f59396f53aa982cd840dc3738a9673e6a08d452e64ec57d1cfff849fdbc2127ca94d8c80101297c52586751b",
    "0x559d2e0d6c1454d8cda345a1b937146bbc5caba9":"0xe0b3505609de35667125fccd5881b238b904d91c2ab4c8d14e6c0bca743f871a464a0bbf1c958ccba8a1d8f2ca4f8ccabc8cde65521c9ce45166b30ce9a0250c1c",
    "0x9bc582f30ec5154a053977083bfd0008dd7e98ee":"0xb837a99331b9e72d04e68aa2439fc66e1b2d2aea8f23f2e5e4988456ba84e1c647ace4ffc5ffcd3bc7c06d3d6a57eb205ef01118e786119cc5ab203b0efac8041c",
    "0xe7e4193dbd90d5906f4c3972f6895b1b4a0380ad":"0x1678a970e5f27e44decc1b3bbb2d98f5454d56970c43bc06310515cad02798b7576903b5c535a59b2167f15a471a4464d58022266d18a9c4f20f5db6d2faf6f61c",
    "0xbec06b52bdd035312059982da5bf0c7927066cb8":"0x013c2e922327f5da73261c3279ce79d40e02e19eee9f62842f0cb8d78acb601114b2be35beff938259241db2f4f5d5b1f9a8eb638cf44a68bf10e6289691900a1c",
    "0x2f5608591ae148589d10cb3cb4358e3b928884cf":"0x401e2f178d1f415f04b49e4d54747e1e043f3b560dffafa471479f4f59e45dc4271738c4f1cc8157b6cf9fd25b65caa97273c0b981d2faefacd55d0357740eeb1c",
    "0x599d5b86a594dff7fa6e0fd18b2369081ed911c3":"0x34e4616f30d5b97e55876d02855db60eac64483b746bbeaa578a4c3b316a0c6827f75a6795a1cde7050a2a2b6bd3e5183fbc94a88343f9be3d643a85000381c21c",
    "0xa20bd1d490207f5899b09e0d8c3a5a09da1bfa75":"0x849ca8f12c0f230ab5fff24d5f1488afa43f3b5ae0dc3fe160051f2de98181850c75441e7a23447294dc8761645e852063dfd7405f23d5c81e0fef37ad5d2d901b",
    "0x1a8235dfbd1a3dde9a0b5b6e8b4444cdf2c1f84b":"0xc2fb6fc8ce5a7087ddc523828216387eb3d626324e407b8d0d3ddd2f99abe48a5b7839025dd0a3c1078c03b8437a4685d84089d20d78949b04563f1df87800181c",
    "0xef11f7036752092344f06d171e9be387a2ae5769":"0x539d4b7893faede117e06facecce2170241408df82ec82a9e6144fbd2d26ba55024f8b411c91fde7018409f8dcaeea12c66226b03766ccfa06714054ba1371cd1c",
    "0x6f27095f3223765533308efb5c64a2ccb6f99b03":"0xb9636d1fc16b33df2c7352db25ea05e45757c705c0b756c6e9076669fa852cb0196be375465fa56528b519b9f5b1efe64c79aa732e633892dfb6d09717db26e71b",
    "0x16c52cecffe56685377cbb254a9faf847f2877fc":"0xdfa88c2247a3990cb5705fc2d268302ed95a0329a0029b6d017eabcc3c9b2c3e2648995f83c0e8d8c69cd2ee0d30a759282cdd57e36fe894aa238d0c764704511b",
    "0xeca154c63eec0da4d753e2ddd874a29d686e7e1a":"0xdcecc1f31a9a12a49abf30ad33b28759321335a089dc6e42143524dae262c2ad0ff1269390d48f5bb73f9b5d615b4957ebb19d747e566e3f42fd25775d9327741c",
    "0xcee7cfdd1d2ab2c53a60978f6c82a32d04f1f966":"0x7a5cea052a0841be3cf70d6b8c848a8d9b491971e7eef7125609fa523f0ad0e830520a3d786560f3d60fd3813c9d2660ea4c04b901ba0afd9cd9c34c35f8138f1b",
    "0xfd1e65915666072caf30c46352112e5b24353f09":"0xf378dd4c10111b5c7f9dd8816fbf159a518280dc48a155a17b6523ddeedd7f8b6a069727ce7d2691661a13e4c16d168dc0156e6b0dcb0c253094729dab9c3ed41b",
    "0x206d89cc00752afeb9e7d7b4efc04afd36d167f7":"0xa4f2b3c77d28cc6bb07445c857b30acd3e3c88b5797b1a96e0f352f37dc1aa94776495454871aacaf5a96027590daa7b2cb0da63abdcd862fa0c0ea03ca6d12c1c",
    "0xe17d0ac3f7c498924d4920bf279c01536686e014":"0x0ec0fad5742b2f495d953f3f0b8a1887e3bc5d364adbe6daeb3670b8bf9dc9571f5283181d186df8fefdca67e8aed6f8c547a005d3e659806d0eaf4a786916921b",
    "0x617abf50708cde67f97f90277c583fe9bf217047":"0x2a2d4c163efdb805add748cafa0a4835f9248bcf84311f596f2759f3158c69ec2b40404c43889e9f6d9240bc06fbb85a7443436aa4ab7f97120d6c835889cf1e1b",
    "0x5418f97b7544485d30c65d16696655896c80f174":"0x9f101160b410cac1a2f9d645c1b11d2355a02ca50f9b7195babf315e85ee690d0b247ac924c2855f512128728e85d96d4956d354a85869024a743abf7f2c4bbc1c",
    "0x40f39a4f12ddb1e84afe6b3496da8c697a793c51":"0x254962b50efc0768013877d786de2497565eaeebd00c85c5e0b520a55e6ebe35228bf4bc8af229276c2af70d938aeeb01b9b82d258d5a9592e340b8a6bb575c31c",
    "0x229f879202fa35b639e2ef6157e26edc9de521fc":"0xd0ecfbcc3074a7af92f8ad3b891cf1370ecbe625dc3d8a1c53bc901f6fcd768b74dac8930957a6436cf3a0aaf7f5553a300ea6ec2079b8d88f1ecdb1b68e01711c",
    "0x32e11cb78ec8fedba2eea79c4ef3812da46f251a":"0xd38490c332bc3a81107a2e38ee8e27352d6a4059666f16321547251d6442ec2e2222236a055b70adafd9107450fc217d551759087f30d921db0ca6baa690b1bc1c",
    "0xda95cd09fdd16c24ee93634878a7db1b649e9765":"0x4f6a0990c6e996c2d3fd220487bf1a17c2538fba951890bd0d667a7adaa75753512b94eeaab105512dbb42e9743333e428d69b58806dcd830c809285945f67601b",
    "0x586cb303a8fb1989ce90f3c6f583f2bf6623ae4b":"0x127f69da034ed2e982ee11352031ed63eec89a91a6e37383592636849af23a25478fb463cdceb94f7c374567e8ae66723d1ce5f9a035fec2f27e85f45b179c3d1b",
    "0x12d252bc0b77bfeb87731e1aeacb5950cbaa38d1":"0xb694f8f8cd21ac00dc246ba831cdb3498feddd83259dc1a99c8380827c1fc6cd3aa3b39aefb144caff0eadd9846fd5c9f72746902bc72306c48fc760cf309f511b",
    "0x799291dd57e59ee3661d7cc5afdf0500ed81aa98":"0x4c7f2ce68b84f282b4c7ab5319b5d17d1d19022c44071809e58c424d25e220250afd531d33eaf0d5bd67b0db8dfe0308a15d2423d0a23d7b2c4e53d858bd1e8a1c",
    "0x874d8c67befdad6f1b4df57c049974814d2e41c3":"0x089d89a76eddf8c2b2f3b8bb25beee6357d0d3b7d189be04c72ae82bf543346778f4b7f20b5701642d5a42943b5df477bea01f27467e559f4f27917229ed153e1b",
    "0x092d658e0d234122914661147cbcdbf022c578f4":"0x935f657b2ee15d3e69d116aef9757e42eed8ba0e126b7e0b3f2bf6e4e26e8b411e5ab4dc22575ccd4ea84ce8940468bf41d929dd1b6acac4dbdcbb4828487f911b",
    "0x96ea489347ae40a7f038eedd5b8b827d9ea0771e":"0x46a1b90ec0618b77c07c6540f4c2b42bd68d8ff061431374e2cc61c18ae749a86eb476770e594099271daaa0a6fc86bfd2fde5d69a9e23fd474ee498224d76721b",
    "0x352c278977730541462133d6edc4ea457bbe9bca":"0xf1be0e940f03afbb0fabc94290129711e641acdbb34e91e81471977f23fca27829250149adfa73d4381b201c7e93be0371516a3c15f626400ed22796b3582be81c",
    "0xf843e26cd3cfc69d09fecf95b2f06a1e931f91d2":"0xc4056a4665aa488c6b0ac26b5f2399fa99fa1b836344c91e7d0df60fd9a0011d629d60407a15501c064b292b61b422820bcbc9b902389aef942c96646aa849e21c",
    "0xc2a4559ac8bafc4f851ec46db72fd0ff72a8e6c8":"0x4b798b8daf27821a82abe374d345455579c659dececed06f32d37919346cde2634f33e69a56b4b98c0fa6bf320bf9a42633d1109bb16a2915563a0d57b6645881b",
    "0x2a4c4243baef098e6a7659ed99001ed606f6e52d":"0xcbb8b52db4c2315e170b17cc36b507bfbc9c73e40e21223df8a440f1c9c3e47836c6b0a3e9b3a06e801feecd0350f9833fd6583dd300255f86bd0ee82c8dac921b",
    "0x4b9f14844463ee268dac79b52b6482c3e4383efd":"0x757489355bd17f725b3b55ea3755da9b3448a06fa051d01afd5de566ec4163646ae078f66422be6d32a800832d5639ce2dd7d62758981130be58ac0834adbe3b1c",
    "0xe8de5f667948f961362e35315423400c00286f02":"0xfda7bf35d6f4e4d8a12157bedb614b5a131363ca61a8eba1a0efb60c6ead96ef12332f56022671c0335983ac10a8612e83a5f0f7b3ba96f0c3e163a9c750b6661c",
    "0x740ffb0839e6b1f7f38436a19fcb15ac4779518b":"0x8a2a8b3acb58168938d64e002cd1bc2a89ffb9d9130359f54bb2fe5a3fe7e90d08da8e94c8eece638eaff906c58e1ebbb7ccdd8594c8850974576fa758fa8bec1c",
    "0x78759fa3914d67fe8a890a1983f5a6782546cdb4":"0x72a182b584cd834e35fa92b2a3bc667c3d64ec989b54dd84562bc99504cfcf4b224b567c68ce0ff498fc8e63861a04ae02a3ffdca9b63e0b2c283de17559fe3f1c",
    "0x2608bf69133ced1aa5ca5dc795bb2fc930a84c91":"0x4edcf7f4428ab7a3d2a03e71086c139f2606db74d64ecf11aa0af14e785a9476148f9b9de1a74dc558e02c75d673efd700b08996236d22693d146e9dd9e71d7d1c",
    "0xd97ebf0fd3486bb09cf5f3c43f757591dcae106e":"0x419192271a53cbc483caf9477e0df2aed60b94da6ad0845aea81809719539aed7db53f5f13d8d15cd16a9af00acfb76b0b89f884929f36503b0c1fb93eb1538a1c",
    "0x130688ba5e4298c8c12cdb86227bc18a86507f0e":"0x673b96e3061254e790478409f3ebb5aaa04a69715bf956b606cb91f8a6eed05d3701f24da81d3e39d203f6afd03b4a84a60f069209c0d06139ae41435a3a1be61c",
    "0x89e77dd7a026c43a6226dc9563aabd70d86770dd":"0x711c2f55016aa078031ce3af00b459fb1aafabfa9888439da8780c211274449155e70d8098ee01a6401d1f0ab965611619eba9953634ce37dd121de171b2a0b61b",
    "0xaee56970fb949d1755e9744f729c40858caaa6cc":"0x65f064b4c44b50074ca4f0c1ae28b45ff56ee4943e1c6199c97f809a9ff2f457469f8d377b6541b3ab08df2faac643587ef9686cad33d1219251a520c016ddd81c",
    "0x816e8761a53af09114380ccf3db3fc157225fbc9":"0x116bf78e85a19f3e3fdf4e770937841148957242510816b37c1adc66901c69d77969f84fab857d5dbe6c8a2382b254ec7440e9a51394309d5808458a4fe9f93a1c",
    "0x33a3db19aaf97143dcfb4998b9b62547b1d331a8":"0xebdaa14f47d56a83177eae90190d74ff962b5f3c0d5cca9ca49beab24cecd1575561c135de7a769d2ce12e8f96bf58e42658b03d3ad624bfa2e3582b0a9d37411b",
    "0x2349334b6c1ee1eaf11cbfad871570ccdf28440e":"0x200799a3e844ec9b84c90f6b9fdcd82905ffc9b7b53350113e792f07e6a50bb30d9d0679dc22402ad330d3231a4ef6dc9755777065b076f9450dfd3d6bcd84b11b",
    "0xa4992bb0500d5a81212e9dd1e77bdf2695cf3daa":"0x040a38491d0836409daa376a653d3c14fefd4f0d7c252286cf570dc8ec096e930ee7561c6af53218e2593c7d4abb32d4f4fe3c0de2d00a995342bc78a8f2dbf21c"
}