import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ThirdwebProvider,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  safeWallet,
} from "@thirdweb-dev/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThirdwebProvider
    activeChain="ethereum"
    clientId="2f93805438e2f3fd619f56a5540ba6c1"
    sdkOptions={{
      gatewayUrls: ["https://megadethdigital.myfilebase.com/ipfs/"],
    }}
    supportedWallets={[
      metamaskWallet(),
      coinbaseWallet(),
      walletConnect(),
      safeWallet({
        personalWallets: [metamaskWallet(), coinbaseWallet(), walletConnect()],
      }),
    ]}
  >
    <App />
  </ThirdwebProvider>
);

reportWebVitals();
